'use client';

import { createTheme } from '@mui/material/styles';

import palette from '@lumirental/lumi-web-theme/lib/lumi.palette';
import typography from '@lumirental/lumi-web-theme/lib/lumi.typography';
import mobileTypography from '@lumirental/lumi-web-theme/lib/lumi.typography.mobile';
import zIndex from '@lumirental/lumi-web-theme/lib/lumi.zindex';

import overrides from '@/themes/lumi.overrides';

export const baseTheme = (direction) => {
  return {
    palette,
    typography: { ...typography, ...mobileTypography },
    spacing: 8,
    zIndex,
    components: overrides(direction),
  };
};

export function getTheme(direction) {
  return createTheme({
    direction,
    ...baseTheme(direction),
  });
}

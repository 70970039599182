const styles = (theme) => ({
  cardWrapper: {
    padding: '14px 0',
    backgroundColor: theme.palette.common.white,
    textAlign: 'center',
  },

  cardList: {
    padding: 0,
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardItem: {
    padding: '0 12px',
  },
  cardImg: {
    width: '100%',
    maxWidth: 56,
  },
});

export default styles;

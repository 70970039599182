import React from 'react';
import { useTranslation } from 'react-i18next';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import styles from './Copyright.style';

const useStyles = makeStyles()(styles);

export default function Copyright() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.copyrightWrapper}>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.copyrightGroup}>
              <Typography className={classes.copyrightText}>
                {t('Copyright_')}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

'use client';

import { useEffect, useState } from 'react';
import Script from 'next/script';

import { GoogleAnalytics } from '@next/third-parties/google';

import { GTM_KEY } from '@lumirental/lumi-web-sdk/dist/constants';
import MOENGAGE_API_ID from '@lumirental/lumi-web-shared/lib/constants/app/MOENGAGE_API_ID';

const AnalyticsProvider = ({ children }) => {
  const [isProduction, setIsProduction] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const { hostname } = window.location;
      setIsProduction(hostname === 'lumirental.com');
    }
  }, []);
  const debugLogs = isProduction ? 0 : 1;

  return (
    <>
      {isProduction ? (
        <meta
          name="google-site-verification"
          content="viSiXpRJLoatqRZBhaK__-V0DbbabVZlY6iXGCr-8yw"
        />
      ) : null}

      {/* Clarity Web */}
      {isProduction ? (
        <Script
          id="clarity-script"
          strategy="worker"
          dangerouslySetInnerHTML={{
            __html: `(function(c,l,a,r,i,t,y){
                          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                      })(window, document, "clarity", "script", "i57w07mthy");`,
          }}
        />
      ) : null}
      {/* End Clarity Web */}

      {/* MoEngage Web */}
      <Script
        id="moengage-script"
        strategy="worker"
        dangerouslySetInnerHTML={{
          __html: `(function(i,s,o,g,r,a,m,n){i.moengage_object=r;t={};q=function(f){return function(){(i.moengage_q=i.moengage_q||[]).push({f:f,a:arguments})}};f=['track_event','add_user_attribute','add_first_name','add_last_name','add_email','add_mobile','add_user_name','add_gender','add_birthday','destroy_session','add_unique_user_id','moe_events','call_web_push','track','location_type_attribute'],h={onsite:["getData","registerCallback"]};for(k
                  in f){t[f[k]]=q(f[k])}for(k in h)for(l in h[k]){null==t[k]&&(t[k]={}),t[k][h[k][l]]=q(k+"."+h[k][l])}a=s.createElement(o);m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m);i.moe=i.moe||function(){n=arguments[0];return t};a.onload=function(){if(n){i[r]=moe(n)}}})(window,document,'script','https://cdn.moengage.com/webpush/moe_webSdk.min.latest.js','Moengage')
                  Moengage = moe({
                  app_id:"${MOENGAGE_API_ID}",
                  debug_logs:${debugLogs},
                  cluster:"DC_4",
                  });`,
        }}
      />
      {/* End MoEngage Web */}

      {children}

      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          title="gtm"
          src={`https://www.googletagmanager.com/ns.html?id=${GTM_KEY}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: ' hidden' }}
        />
      </noscript>
      {/* End Google Tag Manager (noscript) */}

      {/* Google Tag Manager */}
      {isProduction ? <GoogleAnalytics gaId={GTM_KEY.production} /> : null}
      {/* End Google Tag Manager */}
    </>
  );
};

export default AnalyticsProvider;

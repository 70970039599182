import zIndex from '@mui/material/styles/zIndex';

import DIR from '@lumirental/lumi-web-shared/lib/constants/lang/DIR';
import FONT_FAMILY from '@lumirental/lumi-web-shared/lib/constants/lang/FONT_FAMILY';
import commonOverride from '@lumirental/lumi-web-theme/lib/lumi.overrides.common';
import palette from '@lumirental/lumi-web-theme/lib/lumi.palette';

const overrides = (dir) => {
  return {
    ...commonOverride,
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          width: '100%',
          height: '100%',
          WebkitFontSmoothing: 'auto',
          WebkitTextSizeAdjust: '100%',
          TextSizeAdjust: '100%',
          WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
        },
        body: {
          width: '100%',
          height: 'auto',
          margin: 0,
          backgroundColor: palette.common.lightBodyBackground,
          textAlign: 'left',
          '& > #__next': {
            minHeight: '100vh',
            '& > div:only-of-type': {
              minHeight: '100vh',
            },
          },
          '& .grecaptcha-badge': {
            display: 'none !important',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root': {
            color: palette.common.grey300,
            fontSize: 13,
            lineHeight: 1.2,
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-error fieldset, &.Mui-error:hover fieldset': {
              borderColor: palette.common.red400,
            },
            '& fieldset': {
              borderColor: palette.common.grey50,
            },
            '&:hover fieldset': {
              borderColor: palette.common.grey50,
            },
            '&.Mui-focused fieldset': {
              borderColor: palette.common.blue300,
            },
          },
          '& .Mui-disabled': {
            backgroundColor: palette.common.grey20,
            opacity: '0.8',
          },
          '& .MuiFormHelperText-root.Mui-error': {
            color: palette.common.red400,
            fontSize: 13,
            lineHeight: 1.2,
            fontWeight: 400,
            marginLeft: 0,
            marginTop: 8,
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          zIndex: zIndex.modal + 1,
        },
      },
    },
  };
};
export default overrides;

const styles = (theme) => ({
  copyrightWrapper: {
    padding: '32px 0',
  },
  copyrightGroup: {
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  copyrightText: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize12,
    lineHeight: 1.5,
  },
});

export default styles;

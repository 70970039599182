import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/@lumirental/lumi-web-shared/lib/contexts/Config.context.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/@lumirental/lumi-web-shared/lib/contexts/Language.context.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@lumirental/lumi-web-shared/lib/utils/growthBook/GrowthBookWrapper.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@lumirental/lumi-web-shared/lib/utils/growthBook/initGrowthBookClient.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.js\",\"import\":\"Noto_Kufi_Arabic\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"subsets\":[\"latin\"],\"style\":[\"normal\"],\"display\":\"swap\"}],\"variableName\":\"notoArabic\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.js\",\"import\":\"Noto_Sans\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"subsets\":[\"latin-ext\",\"cyrillic-ext\",\"cyrillic\",\"latin\"],\"style\":[\"normal\"],\"display\":\"swap\"}],\"variableName\":\"notoSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/AnalyticsProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/MobileFooter/MobileFooter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/MotionProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/TranslationsProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/containers/LayoutContainer/LayoutContainer.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/src/themes/themeProvider.tsx");

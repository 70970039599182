const styles = (theme) => ({
  footerMiddleWrapper: {
    padding: '56px 0',
    '@media(max-width: 767px)': {
      padding: '32px 0',
    },
  },
  contentWrapper: {
    color: theme.palette.common.white,
  },
  footerMiddleRight: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media(max-width: 767px)': {
      flexWrap: 'wrap',
    },
  },
  footerMiddleLeft: {
    paddingRight: 20,
    '@media(max-width: 959px)': {
      paddingRight: 0,
      marginBottom: 32,
    },
  },
  footerLogoContent: {
    '@media(min-width: 960px)': {
      maxWidth: 310,
    },
  },
  footerLogoLink: {
    display: 'inline-block',
    cursor: 'pointer',
    marginBottom: 16,
  },
  footerLogoText: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize13,
    lineHeight: 1.5,
    marginBottom: 44,
    '@media(max-width: 767px)': {
      marginBottom: 24,
    },
  },
  footerSubtitle: {
    color: theme.palette.primary.dark,
    fontSize: theme.typography.fontSize18,
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: 16,
  },

  footerAppGroup: {
    display: 'flex',
    '& a:first-of-type img': {
      maxWidth: 136,
    },
    '& a:last-child': {
      marginLeft: 16,
      '& img': {
        maxWidth: 120,
      },
    },
  },

  navigation: {
    paddingRight: 12,
    '@media(max-width: 767px)': {
      width: '50%',
      marginBottom: 32,
    },
  },
  navigationTitle: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: 26,
  },
  navigationList: {
    padding: 0,
  },
  navigationItem: {
    padding: 0,
    '&:not(:last-child)': {
      marginBottom: 20,
    },
  },
  navigationLink: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize13,
    lineHeight: 1.5,
    cursor: 'pointer',
    textDecoration: 'none',
  },

  socialMediaLinkGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '40%',
    '@media(max-width: 767px)': {
      width: '100%',
      flexDirection: 'row-reverse',
    },
  },
  socialMedia: {
    '@media(max-width: 767px)': {
      width: '50%',
    },
  },
  socialMediaTitle: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: 26,
  },
  socialMediaList: {
    padding: 0,
  },
  socialMediaItem: {
    padding: 0,
    '&:not(:last-child)': {
      marginBottom: 16,
    },
  },
  socialMediaLink: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize13,
    lineHeight: 1.5,
    textDecoration: 'none',
    cursor: 'pointer',
  },
  socialMediaIcon: {
    marginRight: 16,
    marginLeft: -5,
  },
});

export default styles;

'use client';

import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

import { getTheme } from '@/themes';

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

export const ThemeProvider = ({
  children,
  direction,
}: {
  children: React.ReactNode;
  direction: string;
}): JSX.Element => {
  const theme = getTheme(direction);

  const muiThemeProvider = (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );

  return direction === 'ltr' ? (
    muiThemeProvider
  ) : (
    <CacheProvider value={cacheRtl}>{muiThemeProvider}</CacheProvider>
  );
};

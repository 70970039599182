import React from 'react';

import { makeStyles } from 'tss-react/mui';

import DownloadAppBannerBlock from '@/blocks/DownloadAppBanner';

import CardSection from './components/CardSection';
import ContactUsSection from './components/ContactUsSection';
import Copyright from './components/Copyright';
import NavSection from './components/NavSection';

import styles from './Footer.style';

const useStyles = makeStyles()(styles);

export default function Footer({ language, showBanner = false }) {
  const { classes } = useStyles();

  return (
    <>
      {showBanner && <DownloadAppBannerBlock language={language} />}
      <div className={classes.wrapper}>
        <ContactUsSection language={language} />
        <NavSection language={language} />
        <CardSection />
        <Copyright />
      </div>
    </>
  );
}

'use client';

import { ReactElement, useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';

import { getScreenName } from '@/utils';

import Footer from '@/blocks/Footer/Footer';
import SeoFooter from '@/blocks/SeoFooter';

const MobileFooter = ({ locale }: { locale: string }): ReactElement => {
  const currentPath = usePathname();
  const currentPage = currentPath.substring(currentPath.lastIndexOf('/') + 1);
  const screenName = getScreenName(currentPage);

  const [isCarRentalPage, setIsCarRentalPage] = useState(false);

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.location.pathname.includes('car-rental')
    ) {
      setIsCarRentalPage(true);
    }
  }, []);

  return (
    <>
      {isCarRentalPage ? (
        <SeoFooter language={locale} isMobile screenName={screenName} />
      ) : (
        <Footer language={locale} />
      )}
    </>
  );
};

export default MobileFooter;

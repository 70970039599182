/* eslint-disable camelcase */
import { initReactI18next } from 'react-i18next/initReactI18next';
import { createInstance } from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';

import i18nConfig from '@/i18nConfig';

export default async function initTranslations(
  locale,
  namespaces,
  i18nInstance,
  resources,
) {
  // eslint-disable-next-line no-param-reassign
  i18nInstance = i18nInstance || createInstance();

  i18nInstance.use(initReactI18next);

  if (!resources) {
    const enURL = `https://lumicdn.lumirental.com/translation/web-lumi/en/translation.json`;
    const responseEn = await fetch(enURL);
    const data = {};
    data.en = await responseEn.json();

    const arURL = `https://lumicdn.lumirental.com/translation/web-lumi/ar/translation.json`;
    const responseAr = await fetch(arURL);
    data.ar = await responseAr.json();

    i18nInstance.use(
      resourcesToBackend((language, namespace) => data[language]),
    );
  }

  await i18nInstance.init({
    lng: locale,
    resources,
    fallbackLng: i18nConfig.defaultLocale,
    supportedLngs: i18nConfig.locales,
    preload: resources ? [] : i18nConfig.locales,
    ns: ['home_page', 'city_page'],
    defaultNS: 'home_page',
    compatibilityJSON: 'v3',
    interpolation: {
      prefix: '{',
      suffix: '}',
    },
  });

  return {
    i18n: i18nInstance,
    resources: i18nInstance.services.resourceStore.data,
    t: i18nInstance.t,
  };
}

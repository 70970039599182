'use client';

import {
  AnimateLayoutFeature,
  AnimationFeature,
  ExitFeature,
  GesturesFeature,
  MotionConfig,
} from 'framer-motion';

const MotionProvider = ({ children }) => {
  return (
    <MotionConfig
      features={[
        GesturesFeature,
        AnimationFeature,
        AnimateLayoutFeature,
        ExitFeature,
      ]}
    >
      {children}
    </MotionConfig>
  );
};

export default MotionProvider;

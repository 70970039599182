import React from 'react';

import { makeStyles } from 'tss-react/mui';

import DownloadAppBannerBlock from '@/blocks/DownloadAppBanner';
import CardSection from '@/components/CardSection';
import ContactUsSection from '@/components/ContactUsSection';
import Copyright from '@/components/Copyright';
import NavSection from '@/components/NavSection';

import styles from '@/blocks/SeoFooter/SeoFooter.style';

const useStyles = makeStyles()(styles);

export default function SeoFooter({ language, isMobile, screenName }) {
  const { classes } = useStyles();

  return (
    <>
      <DownloadAppBannerBlock language={language} isMobile={isMobile} />
      <div className={classes.wrapper} id="footerSec">
        <ContactUsSection language={language} />
        <NavSection language={language} screenName={screenName} />
        <CardSection />
        <Copyright />
      </div>
    </>
  );
}

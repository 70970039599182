'use client';

import { useState } from 'react';
import { Detector } from 'react-detect-offline';
import { useTranslation } from 'react-i18next';

import Snackbar from '@mui/material/Snackbar';

export default function LayoutContainer({ children }) {
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [message, setMessage] = useState('');
  const { t } = useTranslation();

  const handleChange = (online) => {
    setShowSnackBar(true);
    setMessage(online ? t('online') : t('offline'));
  };

  return (
    <>
      {children}
      <Detector
        onChange={handleChange}
        render={() => (
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={showSnackBar}
            autoHideDuration={3000}
            message={message}
            onClose={() => setShowSnackBar(false)}
            data-testid="layoutSnackbar"
          />
        )}
      />
    </>
  );
}
